<script>
import Layout from "../../layouts/table";
import {mapGetters} from "vuex";
import moment from "moment";

export default {
    data() {
        return {
            page: (this.pager && this.pager.current_page) || 1,
            perPage: (this.pager && this.pager.limit) || 10,
            currentPage: (this.pager && this.pager.current_page) || 1,
            sortBy: (this.pager && this.pager.column) || 'date',
            sortDesc: (this.pager && this.pager.desc) || true,
            formatter: new Intl.NumberFormat('hu-HU', {
                style: 'currency',
                currency: 'HUF',
                maximumSignificantDigits: 1
            }),
            modal: false
        };
    },
    computed: {
        ...mapGetters({
            items: 'sms/in',
            item: 'message/item',
            pager: 'sms/inPager',
            itemsLoading: 'sms/loading',
        }),
        rows: function () {
            return this.items && this.items.length
        },
        fields: function () {
            return [
                {
                    key: "receive_date",
                    label: this.$t('sms.date'),
                    thClass: 'gridjs-th',
                    formatter: (value) => {
                        return moment(value).format('YYYY. MM. DD. HH:mm')
                    },
                    sortable: true
                },
                {
                    key: "src_address",
                    label: this.$t('sms.sender'),
                    thClass: 'gridjs-th border-left-0',
                    sortable: true
                },
                {
                    key: "dest_address",
                    label: this.$t('sms.receiver'),
                    thClass: 'gridjs-th border-left-0',
                    sortable: true
                },
                {
                    key: "message",
                    label: this.$t('sms.message'),
                    thClass: 'gridjs-th',
                    sortable: true
                },
            ];
        },
    },
    methods: {
        open: function (id) {
            this.$store.dispatch('message/fetchOne', id).then(() => {
                this.modal = true;
            });
        },

        toPage: function (val) {
            this.page = val
            this.delayedFetch()
        },

        delayedFetch: function () {
            this.$store.dispatch('sms/fetchInDelayed', {
                limit: this.perPage,
                offset: this.pager && this.pager.offset,
                page: this.page,
                sorting: {
                    column: this.sortBy,
                    desc: this.sortDesc
                }
            });
        },
    },
    components: {
        Layout,
    },
    watch: {
        sortBy: function () {
            this.delayedFetch();
        },
        sortDesc: function () {
            this.delayedFetch();
        },
    },
    created() {
        document.title = this.setTitle(this.$t('sms.sms_received'));
        this.$store.dispatch('sms/fetchIn');
    }
};
</script>

<template>
    <Layout>
        <b-modal centered hide-footer v-model="modal" :title="item.subject">
            {{ item.message }}
        </b-modal>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{ $t('sms.sms_received') }}</h4>
            </div>
            <div class="card-body p-0 pb-3 p-md-3">
                <!-- Table -->
                <div class="table-responsive ">
                    <div class="mb-0">
                        <b-table
                            :items="items"
                            :fields="fields"
                            responsive="sm"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :busy="itemsLoading"
                            hover
                            head-variant="light"
                            no-local-sorting
                        >
                            <template #cell(actions)="data">
                                <b-button size="sm" variant="primary" class="me-2" @click="open(data.item.m_id)">{{ $t('messages.view') }}</b-button>
                            </template>
                            <template #table-busy>
                                <div class="d-flex justify-content-center">
                                    <b-spinner></b-spinner>
                                </div>
                            </template>
                        </b-table>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="d-flex w-100 align-items-center justify-content-center justify-content-md-end">
                            <div
                                class="dataTables_paginate paging_simple_numbers"
                            >
                                <ul class="pagination pagination-rounded mb-0">
                                    <!-- pagination -->
                                    <b-pagination
                                        v-model="page"
                                        :total-rows="pager.count"
                                        :per-page="perPage"
                                        @change="toPage"
                                    ></b-pagination>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
